@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		font-family: "Rubik", system-ui, sans-serif;
	}
}

::-webkit-scrollbar {
	width: 0;
}

.show-scrollbar {
	overflow: auto; /* Enable scrolling */
}

.show-scrollbar::-webkit-scrollbar {
	width: 12px; /* Example width */
}

.show-scrollbar::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	border-radius: 10px;
}

.show-scrollbar::-webkit-scrollbar-thumb:hover {
	background-color: grey;
}

.css-fix {
	white-space: pre-wrap;
}

.background {
	min-height: 100vh;
	background-color: white;
}

.dark.background {
	background-color: black;
}

.button-gradient {
	background-image: linear-gradient(81.75deg, #4b74ff 0%, #9281ff 100%);
}

.dark .active {
	color: #9281ff;
	background-color: #3a3c43;
	/* border-radius: 0.8em; */
}

.active {
	background-color: #9281ff;
	/* border-radius: 0.8em; */
	color: #fff;
}

.dark .active > span > svg > path {
	fill: #9281ff;
}

.active > span > svg > path {
	fill: #fff;
}

.transaction-button > button {
	padding-block: 0.2em;
	padding-inline: 0.5em;
	border-radius: 1.8em;
	font-weight: 600;
	color: black;
}

.text-gradient {
	color: transparent;
	background-clip: text;
	background-image: linear-gradient(90deg, #51b960, #83dc90);
}

.card-gradient {
	background-image: none;
	background-color: #e7eaee;
	box-shadow: none;
}

.dark .card-gradient {
	box-shadow: 2.96797px 2.96797px 7.41993px -23.7438px rgba(0, 0, 0, 0.1);
	background-image: linear-gradient(to bottom right, #282828, #00000028);
	background-color: transparent;
}

.my-gradient {
	background-image: linear-gradient(
		302.47deg,
		rgba(255, 255, 255, 0.8) 2.27%,
		rgba(168, 154, 255, 0.260833) 98.52%
	);
	box-shadow: inset -1px -1px 1px rgba(185, 185, 185, 0.1);
}

.my-gradient2 {
	background-image: linear-gradient(
		302.47deg,
		rgba(255, 255, 255, 0.8) 2.27%,
		rgba(168, 154, 255, 0.260833) 98.52%
	);
	/* box-shadow: inset -1px -1px 1px rgba(185, 185, 185, 0.1); */
	/* box-shadow: #9281FF 0px 3px 8px; */

	/* box-shadow: rgb(111, 122, 255, 0.25) 0px 50px 100px -20px, rgb(111, 122, 255, 0.3) 0px 30px 60px -30px, rgb(111, 122, 255, 0.35) 0px -2px 6px 0px inset; */
	/* box-shadow: rgba(171, 93, 255, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
}

.dark .my-gradient {
	background-image: linear-gradient(
		302.85deg,
		rgba(168, 154, 255, 0) -1.23%,
		rgba(168, 154, 255, 0.260833) 99.99%,
		rgba(168, 154, 255, 0.8) 100%
	);
	box-shadow: 1px 1px 1px rgba(185, 185, 185, 0.1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.approved-btn {
	background-image: linear-gradient(
		97.78deg,
		#51b960 7.43%,
		#51b960 7.43%,
		#51b960 7.43%,
		#83dc90 90.63%
	);
}

.rejected-btn {
	background-image: linear-gradient(97.67deg, #e73838 1.07%, #ffbaba 100%);
}

.unsured-btn {
	background-image: linear-gradient(
		276.08deg,
		rgba(255, 255, 255, 0.04) 2.02%,
		rgba(255, 255, 255, 0.4) 2.03%,
		#ffffff 99.33%
	);
}

.css-1hb7zxy-IndicatorsContainer {
	display: none !important;
}

.themetoggle > svg > path {
	fill: black;
}

.themetoggle-box {
	box-shadow: 0px 0.5px 10px #9281ff;
}

.dark .themetoggle-box {
	box-shadow: 0px 0.5px 10px rgba(255, 255, 255, 0.7);
}

.dark .themetoggle > svg > path {
	fill: white;
}

.dark .CreateProfileIcon > svg > path {
	fill: #fff;
}

.CreateProfileIcon > svg > path {
	fill: black;
}

/* For Firefox */
progress::-moz-progress-bar {
	background: rgb(37 99 235);
	border-radius: 0.5em;
}

/* For Chrome or Safari */
progress::-webkit-progress-value {
	background: rgb(37 99 235);
	border-radius: 0.5em;
}

progress::-webkit-progress-bar {
	border-radius: 0.5em;
	background-color: rgb(243 244 246);
}

.dark progress::-webkit-progress-bar {
	background-color: rgb(107 114 128);
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	border: 0;
	box-sizing: border-box;
}

/* ::-webkit-scrollbar {
	width: 0px;
}

::-webkit-scrollbar-track {
	background: rgb(235, 233, 233);
} */

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.box-shadow {
	box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
		rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.box-shadow2 {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.box-shadow3 {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
		rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.box-shadow4 {
	box-shadow: rgba(200, 142, 253, 0.25) 0px 30px 60px -12px inset,
		rgba(200, 142, 253, 0.25) 0px 18px 36px -18px inset;
}

.box-shadow5 {
	box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.box-shadow6 {
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.my-text-shadow {
	text-shadow: 3px 3px 5px #9e9e9eaf;
}

.my-table-row:hover {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.gradient-right {
	background-image: linear-gradient(
		302.85deg,
		rgba(255, 255, 255, 0.8) 2.27%,
		rgba(168, 154, 255, 0.260833) 98.52%
	);
}

.gradient-bottom {
	background-image: linear-gradient(
		0deg,
		rgba(255, 255, 255, 0.8) 2.27%,
		rgba(168, 154, 255, 0.260833) 98.52%
	);
}

.gradient-top {
	background-image: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.8) 2.27%,
		rgba(168, 154, 255, 0.260833) 98.52%
	);
}

/* sticky section style*/
.profile section,
.home-page-sticky {
	overflow: hidden;
	height: 85vh;
}

.profile section:hover,
.home-page-sticky:hover {
	overflow: auto;
}

.viewPool-navbar ul li {
	text-decoration: none;
	padding: 0.7rem 1.2rem;
	font-weight: 400;
	/* width: 33.3%; */
	text-align: center;
	font-size: 1.3rem;
}

.viewPool-navbar .inactive {
	color: rgb(175, 174, 174);
}

.viewPool-navbar .active {
	border-radius: 2px;
}

.viewPool-navbar .bor {
	border-left: 2px solid rgb(175, 174, 174);
}

@layer base {
	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"] {
		-webkit-appearance: none;
		margin: 0;
		-moz-appearance: textfield !important;
	}
}

.checkbox {
	--checkbox-color: #ffffff;
	/* Set the checkbox color to white */
}

.checkbox input[type="checkbox"] {
	appearance: none;
	/* Remove the default checkbox appearance */
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 16px;
	height: 16px;
	border-radius: 3px;
	border: 1px solid #cccccc;
	background-color: var(--checkbox-color);
	/* Set the background color to white */
}

.checkbox input[type="checkbox"]:checked {
	background-color: #ffffff;
	/* Set the background color to white when checked */
}

.modebar-container {
	top: 10% !important; /* Negative margin to move the bar up */
}
